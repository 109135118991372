"use strict";

function headerDropdown() {
    if ($(".dropdown-menu li").length) {
        $(".dropdown-menu li").on('click', function () {
            $(this).parents(".dropdown").find('.btn-dropdown').html($(this).text() + ' <i class="fa fa-angle-down"></i>');
            $(this).parents(".dropdown").find('.btn-dropdown').val($(this).data('value'));
        });
    }
    ;
}

function menuScroll() {
    if ($('.menu_fixed.main_menu').length) {
        var sticky = $('.menu_fixed.main_menu'),
            scroll = $(window).scrollTop();

        if (scroll >= 190) sticky.addClass('fixed');
        else sticky.removeClass('fixed');

    }
    ;
}

function submitForm() {
    if ($(".dropdown-menu li").length) {
        $(".dropdown-menu li").on('click', function () {
            $(this).parents(".dropdown").find('.btn').html($(this).text() + ' <i class="fa fa-angle-down"></i>');
            $(this).parents(".dropdown").find('.btn').val($(this).data('value'));
        });
    }
    ;
}
function owlCarousel() {
    if ($("#owl-demo").length) {
        var owl = $("#owl-demo");

        owl.owlCarousel({
            items: 4,
            itemsDesktop: [992, 3],
            itemsDesktopSmall: [768, 2],
            itemsTablet: [450, 1],
            itemsMobile: false,// itemsMobile disabled - inherit from itemsTablet option
            pagination: false,
            autoPlay: 5000
        });

        // Custom Navigation Events
        $(".next").click(function () {
            owl.trigger('owl.next');
        })
        $(".prev").click(function () {
            owl.trigger('owl.prev');
        })

    }
}

function thmAccrodion() {
    if ($('.accordion > .panel').length) {
        $('.accordion > .panel').on('show.bs.collapse', function (e) {
            var heading = $(this).find('.panel-heading');
            heading.addClass("active-panel");

        });

        $('.accordion > .panel').on('hidden.bs.collapse', function (e) {
            var heading = $(this).find('.panel-heading');
            heading.removeClass("active-panel");
            //setProgressBar(heading.get(0).id);
        });

    }
}


function initCalc() {
    $('[name=tax-sys],[name=pri-doc],input.opt').click(function () {
        calculate();
    });
    $('#docs-num-slider').slider({
        range: "min",
        value: 20,
        min: 0,
        max: 240,
        slide: function (event, ui) {
            $("#doc-num").val(ui.value);
            calculate();
        }
    });
    $("#doc-num").val(20);
    calculate();
}
function calculate() {
    var period = 'месяц';
    var $slider = $("#docs-num-slider");
    var taxSys = $('[name=tax-sys]').filter(':checked').val();
    var result = 0;
    if (taxSys == 'nulled') {
        result = 2000;
        period = 'квартал';
    } else {
        var value = parseInt($slider.slider('value'));
        // console.log('$slider.val='+value);
        if (value <= 20)
            value = 0;
        else if (value <= 50)
            value = 1;
        else if (value <= 100)
            value = 2;
        else if (value <= 200)
            value = 3;
        else
            value = 4;
        var tariffs = {
            bez: {
                osno: [7000, 13000, 18000, 30000, 34000],
                usn15: [6500, 12000, 17000, 28000, 32000],
                usn6: [6500, 12000, 17000, 28000, 32000]
            },
            s: {
                osno: [7500, 14000, 19000, 32000, 36000],
                usn15: [7000, 12500, 18000, 30000, 34000],
                usn6: [7000, 12500, 18000, 30000, 34000]
            }
        };
        var priDocs = $('[name=pri-doc]').filter(':checked').val();
        console.log('priDocs=' + priDocs + ' taxSys=' + taxSys + ' idx=' + value + ' arr=' + tariffs[priDocs][taxSys]);
        var cost = tariffs[priDocs][taxSys][value];
        result = cost;
        $('#calc .opt').each(function () {
            if ($(this).is(':checked'))
                result += cost * 0.2;
        });
    }

    $('#calc-result').text(result);
    $('#calc-period').text(period);

}

// function topSearch() {
//     if ($('#search').length) {
//         $('#button').click(function () {
//             $('#search').fadeToggle(100);
//         });
//         $('#button').on('click', function (e) {
//             $(this).toggleClass("search_click"); //you can list several class names
//             e.preventDefault();
//         });
//
//         $('.main_menu nav ul li.dropdown_menu').append(function () {
//             return '<i class="fa fa-bars"></i>';
//         });
//         $('.main_menu nav ul li.dropdown_menu .fa').on('click', function () {
//             $(this).parent('li').children('ul').slideToggle();
//         });
//     }
//     ;
// }

function selectDropdown() {
    if ($(".selectmenu").length) {
        $(".selectmenu").selectmenu();
    }
}

jQuery(document).on('ready', function () {
    (function ($) {
        headerDropdown();
        submitForm();
        owlCarousel();
        thmAccrodion();
        if ($('#calc').length)
            initCalc();
        // topSearch();
        selectDropdown();
        $('.testimonial').each(function () {
            $(this).parent().css('position', 'relative');
        });
        $('.btn-contact').on('click', function (e) {
            e.preventDefault();
            $('div.d-ajax-result').addClass('hidden');
            $('#m-contact form').removeClass('hidden');
            $('#m-contact').modal('show');
        });
        $('body').on('submit', 'form.f-ajax', function (e) {
            e.preventDefault();
            var $form = $(this);
            $('p.error', $form).remove();
            $('.error', $form).removeClass('error');
            var fields = $form.serialize();
            fields += '&referer=' + encodeURIComponent(window.location.href);
            // console.log(fields);
            $.ajax({
                url: $form.data('go'),
                type: 'POST',
                data: fields,
                dataType: 'json',
                success: function (data) {
                    if (data.success) {
                        var callback = $form.data('callback');
                        if (callback) {
                            eval(callback);
                        }
                    } else {
                        if (data.errors) {
                            var $el;
                            $.each(data.errors, function (index, value) {
                                $el = $('input[name="' + index + '"],select[name="' + index + '"],textarea[name="' + index + '"]', $form).addClass('error').parent();
                                $el.append('<p class="error">' + value + '</p>');
                            });
                        }
                        if (data.message)
                            $form.prepend('<p class="error">' + data.message + '</p>');
                    }
                },
                error: function (data) {
                    var errors = $.parseJSON(data.responseText);
                    var $el;
                    $.each(errors, function (index, value) {
                        $el = $('#i-' + index, $form).addClass('error').parent();
                        // console.log('#i-' + index.replaceAll('.', '-') + '.length=' + $('#i-' + index.replaceAll('.', '-'), $form).length);
                        for (var k in value) {
                            $el.append('<p class="error">' + value[k] + '</p>');
                        }
                    });
                }
            });
        });
    })(jQuery);

    $("img").unveil();
    $('a.swipebox').swipebox();
});
var calcFilled = false;
function fillCalc() {
    var opt = [];
    $('#calc .opt').each(function () {
        if ($(this).is(':checked'))
            opt.push($(this).parent().text());
    });
    var str = 'Заполнен калькулятор.\nСистема налогообложения: ' + $('[name=tax-sys]').filter(':checked').parent().text() + '\nПервичная документация:' + $('[name=pri-doc]').filter(':checked').parent().text() + '\nКоличество документов:' + $("#docs-num-slider").slider('value') + '\n'+(opt.length>0?'Наличие у организации: '+opt.join(', '):'')+'\nИтого: '+$('#calc-result').text()+' руб.';
    $('#m-contact form #i-calc').val(str);
}
function contactSuccess() {
    $('#m-contact form').addClass('hidden');
    $('#m-contact div.d-ajax-result').removeClass('hidden');
}
jQuery(window).on('scroll', function () {
    (function ($) {
        menuScroll();
    })(jQuery);
});






